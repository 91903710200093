/* Styles par défaut */
.funnel-container {
    /* Vos styles par défaut ici */
  }
  
  /* Requête média pour les petits écrans */
  @media screen and (max-width: 768px) {
    .funnel-container {
        text-align: center;
        color: gray;
    }
    h2 {
        color: #2556CA;
    }
    /* styles.css (ou tout autre nom de fichier CSS que vous utilisez) */

/* Style du bouton */
.next-button {
    background-color: red; /* Couleur de fond rouge */
    color: white; /* Couleur du texte en blanc */
    padding: 10px 20px; /* Rembourrage (padding) pour l'espace autour du texte */
    border: none; /* Supprimez la bordure par défaut */
    border-radius: 5px; /* Coins arrondis */
    text-decoration: none; /* Supprimez toute décoration de texte (comme le soulignement) */
    cursor: pointer; /* Curseur de la souris en forme de main */
    /* Autres styles de décoration que vous souhaitez ajouter */
    font-weight: bolder;
  }
  
  /* Style au survol du bouton (exemple : changement de couleur de fond) */

  /* Styles pour l'élément input de type date */
  input {
    width: 90%; /* Prend 100% de la largeur de l'écran */
    padding: 10px; /* Rembourrage (padding) pour l'espace autour du texte */
    border: 1px solid #ccc; /* Bordure grise */
    border-radius: 5px; /* Coins arrondis */
    background-color: #f8f8f8; /* Couleur de fond grise claire */
    color: #333; /* Couleur du texte */
    font-size: 16px; /* Taille de police */
    /* Autres styles de décoration que vous souhaitez ajouter */
  }

  /* Style au survol de l'élément input */
  input:hover {
    border-color: #666; /* Changement de couleur de bordure au survol */
  }
  p {
    font-size: 18px; /* Taille de police plus grande pour une meilleure lisibilité sur mobile */
    color: #333; /* Couleur du texte */
    margin: 10px 0; /* Marge autour du paragraphe */
    /* Autres styles de décoration que vous souhaitez ajouter */
  }

  .question-container {
    margin-bottom: 53px;
  }
  /* styles.css (ou tout autre nom de fichier CSS que vous utilisez) */

/* Style pour le composant MultiCheckbox sur mobile */
/* styles.css (ou tout autre nom de fichier CSS que vous utilisez) */

/* Style pour le conteneur des cases à cocher sur mobile */
.checkbox-container {
    width: 90%; /* Prend 100% de la largeur de l'écran */
    padding: 10px; /* Rembourrage (padding) pour l'espace autour du conteneur */
    border: 1px solid #ccc; /* Bordure grise pour le conteneur */
    border-radius: 5px; /* Coins arrondis pour le conteneur */
    background-color: #f8f8f8; /* Couleur de fond grise claire pour le conteneur */
    color: #333; /* Couleur du texte pour le conteneur */
    font-size: 16px; /* Taille de police pour le conteneur */
    margin-bottom: 10px; /* Marge en bas du conteneur */
    /* Autres styles de décoration que vous souhaitez ajouter pour le conteneur */
    margin: auto;
  }
  
  /* Style pour l'étiquette des cases à cocher sur mobile */
  .checkbox-label {
    display: flex; /* Utilisez Flexbox pour aligner les éléments horizontalement */
    align-items: center; /* Alignez le contenu verticalement au centre */
    cursor: pointer; /* Indiquez que l'élément est cliquable */
  }
  
  /* Style pour la case à cocher personnalisée sur mobile */
  .custom-checkbox {
    margin-right: 10px; /* Marge à droite pour séparer la case à cocher du texte */
    width: 45%;
    /* Autres styles de décoration que vous souhaitez ajouter pour la case à cocher */
  }
  
/* styles.css (ou tout autre nom de fichier CSS que vous utilisez) */

/* Style pour le bouton cercle personnalisé */
.custom-circle-button {

    border: none; /* Supprimez la bordure par défaut */
    border-radius: 50%; /* Crée un cercle avec des coins arrondis à 50% */
    width: 50px; /* Largeur du cercle */
    height: 50px; /* Hauteur du cercle, cela dépend de la taille que vous souhaitez */
    font-size: 24px; /* Taille de police pour l'icône de la flèche */
    line-height: 50px; /* Alignement vertical du texte au centre */
    text-align: center; /* Alignement horizontal du texte au centre */
    cursor: pointer; /* Curseur de la souris en forme de main */
    /* Autres styles de décoration que vous souhaitez ajouter */
  }
  
  /* styles.css (ou tout autre nom de fichier CSS que vous utilisez) */

/* Style pour le bouton cercle personnalisé */
.custom-circle-button {
    background-color: #f8f8f8; /* Couleur de fond grise claire */
    color: #333; /* Couleur du texte */
    border: none; /* Supprimez la bordure par défaut */
    border-radius: 50%; /* Crée un cercle avec des coins arrondis à 50% */
    width: 50px; /* Largeur du cercle */
    height: 50px; /* Hauteur du cercle, cela dépend de la taille que vous souhaitez */
    cursor: pointer; /* Curseur de la souris en forme de main */
    display: flex; /* Utilisez Flexbox pour aligner les éléments horizontalement */
    align-items: center; /* Alignez le contenu verticalement au centre */
    justify-content: space-between; /* Espacez les éléments horizontalement */
    padding: 0 10px; /* Ajoutez un espacement entre le texte et le cercle */
    /* Autres styles de décoration que vous souhaitez ajouter */
  }
  
  /* Style pour la flèche à gauche du bouton */
  .arrow-left {
    margin-right: 10px; /* Marge à droite de la flèche pour l'espacement */
  }
  .back_button {
    padding: 0 15px;
    margin-top: 13px;
  }

  select {
    width: 96%; /* Prend 100% de la largeur de l'écran */
    padding: 10px; /* Rembourrage (padding) pour l'espace autour du texte */
    border: 1px solid #ccc; /* Bordure grise */
    border-radius: 5px; /* Coins arrondis */
    background-color: #f8f8f8; /* Couleur de fond grise claire */
    color: #333; /* Couleur du texte */
    font-size: 16px; /* Taille de police */
    /* Autres styles de décoration que vous souhaitez ajouter */
  }

  /* Style pour l'élément textarea sur mobile */
textarea {
    width: 100%; /* Prend 100% de la largeur de l'écran */
    padding: 10px; /* Rembourrage (padding) pour l'espace autour du texte */
    border: 1px solid #ccc; /* Bordure grise */
    border-radius: 5px; /* Coins arrondis */
    background-color: #f8f8f8; /* Couleur de fond grise claire */
    color: #333; /* Couleur du texte */
    font-size: 16px; /* Taille de police */
    margin-bottom: 10px; /* Marge en bas du textarea */
    /* Autres styles de décoration que vous souhaitez ajouter */
  }
  
  /* Style au survol de l'élément textarea */
  textarea:hover {
    border-color: #666; /* Changement de couleur de bordure au survol */
  }
  }
  
  /* Requête média pour les écrans moyens */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .funnel-container {
      /* Ajustez les styles pour les écrans moyens ici */
    }
  }
  
  /* Requête média pour les grands écrans */
  @media screen and (min-width: 1025px) {
    .funnel-container {
      /* Ajustez les styles pour les grands écrans ici */
    }
  }
