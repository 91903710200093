.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 10px 20px;
  }
  
  .img_header{
    width: 40%;
  }
  .header{
    background-color: #000;
    margin: 0;
  }
  .header p {
    color: #FFF;
  }

  